<template>
  <v-row class="flex-row align-self-stretch">
    <v-navigation-drawer
      expand-on-hover
      v-model="drawer"
      color="primary"
      permanent
      absolute
      clipped
      dark>
      <v-list dense>

        <!-- lien menu seul -->
        <v-list-item link :to="`/patient/${patientId}`">
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retour au dossier patient</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="`/patient/${patientId}/interview/${interviewId}`">
              <v-list-item-icon>
                <v-icon>mdi-apps</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Général</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Aperçu global de l'entretien, permet de charger ou
            supprimer une transcription
          </span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/text-to-speech')">
              <v-list-item-icon>
                <v-icon>mdi-microphone-message</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Transcription automatique</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Transcire automatiquement un fichier audio</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <v-divider></v-divider>

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/basics')">
              <v-list-item-icon>
                <v-icon>mdi-ruler</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Métriques</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Analyse brute: nombre de mots, longueurs, graphes cumulatifs des lemmes
            et fréquences des POS-tags
          </span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/syntax')">
              <v-list-item-icon>
                <v-icon>mdi-alphabetical</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Syntaxe</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Affiche les arbres de dépendances syntaxiques
            pour les phrases composant le dialogue
          </span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item disabled v-on="on" link :to="url('tool/semantic')">
              <v-list-item-icon>
                <v-icon disabled>mdi-shape-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sémantique</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Analyse sémantique</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/embeddings')">
              <v-list-item-icon>
                <v-icon>mdi-alpha</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Plongements</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Affiche les similarités entre les tours de paroles</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/discursive')">
              <v-list-item-icon>
                <v-icon>mdi-code-tags</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Parsing discursif</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Parsing discursif</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/dialogic')">
              <v-list-item-icon>
                <v-icon>mdi-comment-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Parsing dialogique</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Parsing dialogique</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/prosody')">
              <v-list-item-icon>
                <v-icon>mdi-comment-processing-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Prosodie</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Analyses des temps de réactions</span>
        </v-tooltip>
        <!-- fin lien menu seul -->
        <v-divider></v-divider>

        <!-- lien menu seul -->
        <v-tooltip max-width="300" :position-x="50" right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" link :to="url('tool/export')">
              <v-list-item-icon>
                <v-icon>mdi-export</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Export</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Exporter les résultats</span>
        </v-tooltip>
        <!-- fin lien menu seul -->

        <!-- lien menu seul -->
        <v-list-item link disabled>
        <v-list-item-icon>
          <v-icon disabled>mdi-help</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Obtenir de l'aide</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
      </v-list>
    </v-navigation-drawer>

    <v-col cols="12" class="pt-0">
    <p class="title" style="margin-left: 60px;">{{ interviewName }}</p>
    <router-view @notify="snack"></router-view>
    </v-col>
  </v-row>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';

export default {
  name: 'Interview',
  mixins: [MixinInterviews],
  props: {
    interviewId: String, // Set by router
    patientId: String, // Set by router
  },
  data: () => ({
    drawer: true,
    mini: true,
    interviewName: '',
  }),

  async mounted() {
    const data = await this.getInterviewInfo(this.patientId, this.interviewId);

    if (data) {
      this.interviewName = data.name;
    }
  },

  methods: {
    snack(e) {
      this.$emit('notify', e);
    },
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },
  },
};
</script>
